<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4>{{ $t('backoffice.roles.community-roles') }}</h4>
        <div class="d-flex flex-wrap">
          <b-button v-b-modal.new-role variant="primary" class="ml-1  align-self-start">
            {{ $t('backoffice.roles.new-role') }}
          </b-button>
        </div>
      </div>
      
      <!-- Roles -->
      <b-table
        class="position-relative"
        :items="roles"
        responsive
        :small="isSmall"
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="loading"
      >
        <!-- Column: Role -->
        <template #cell(fullname)="{ item }">
          <b-media class="d-flex align-items-center">
            <b-link :to="getRoleLocation(item)" class="text-dark d-flex">
              <div class="ml-1">
                <p class="font-weight-bold mb-0 text-dark">
                  <feather-icon
                    v-if="!item.isGlobal"
                    v-b-tooltip.hover
                    :title="$t('tooltips.roles.edit')"
                    size="14"
                    icon="Edit2Icon"
                    class="mr-50 text-primary"
                  />
                  {{ item.name }}
                </p>
              </div>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Tagname -->
        <template #cell(tagName)="{ item }">
          <div class="text-nowrap">
            <p class="mb-0 ml-1 pl-25">
              {{ item.tagName }}
            </p>
          </div>
        </template>

        <!-- Column: isGlobal -->
        <template #cell(isGlobal)="{ item }">
          <div class="text-nowrap">
            <p class="mb-0 ml-1 pl-25">
              <b-badge :variant="item.isGlobal ? 'light-primary' : 'light-info'">
                {{ item.isGlobal ? $t('backoffice.roles.global-role') : $t('backoffice.roles.custom') }}
              </b-badge>
            </p>
          </div>
        </template>
        <!-- Column: default -->
        <template #cell(default)="{ item }">
          <div class="text-nowrap cursor-pointer" @click="handleDefaultRole(item)">
            <p class="mb-0 ml-1 pl-25">
              <small v-if="isDefaultRole === item.key"><b-icon-star-fill class="text-warning"/> {{ $t('backoffice.roles.default-role') }}</small>
              <small v-else><b-icon-star class="text-muted"/></small>
            </p>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>

      <!-- Create -->
      <b-modal
        id="new-role"
        :title="$t('backoffice.roles.new-role')"
        :ok-title="$t('form.actions.save')"
        ok-only
        :ok-disabled="isDisabled"
        @hidden="isValid = null"
        @ok="saveRols()"
      >
        <b-form-group class="flex-grow-1">
          <label for="rol-name" class="font-weight-bold"> {{ $t('backoffice.roles.name') }}*</label>
          <b-form-input
            id="rol-name"
            v-model="currentRole.name"
            type="text"
            :placeholder="$t('form.type-placeholder')"
            :state="isValid"
            @input="validator()"
          />
          <small v-if="isValid === false" class="text-danger mt-25">
            {{ $t('backoffice.roles.validator-message') }}
          </small>
        </b-form-group>
      </b-modal>

      <!-- Default -->
      <b-modal
        v-model="isDefaultModalOpened"
        :title="$t('backoffice.roles.default-role')"
        :ok-title="$t('form.actions.save')"
        size="lg"
        ok-only
        hide-footer
        @hidden="isValid = null"
      >
        <b-form-group v-if="isDefaultRole !== currentItem.key" class="flex-grow-1">
          <h4 class="text-center mb-1">{{currentItem.name}}</h4>
          <h5 class="px-2 text-center">
            {{ $t('backoffice.roles.default-question') }}
          </h5>
          <h5 class="mb-2 px-2 text-center font-weight-normal">
            {{ $t('backoffice.roles.default-message') }}
          </h5>
          <div class="d-flex justify-content-center mb-2 flex-wrap">
            <b-button
              variant="outline-danger"
              target="_blank"
              class="mt-25"
              @click="isDefaultModalOpened = false"
            >
              {{ $t('backoffice.roles.discard') }}
            </b-button>
            <b-button
              variant="primary"
              target="_blank"
              class="ml-md-1 mt-25"
              @click="handleDefault"
            >
              {{ $t('backoffice.roles.confirm') }}
            </b-button>
          </div>
        </b-form-group>
        <b-form-group v-else class="flex-grow-1">
          <h4 class="text-center mb-1">{{currentItem.name}}</h4>
          <h5 class="mb-2 px-2 text-center">
            {{ $t('backoffice.roles.already-default') }}
          </h5>
          <div class="d-flex justify-content-center mb-2 flex-wrap">
            <b-button
              variant="outline-danger"
              target="_blank"
              class="mt-25"
              @click="isDefaultModalOpened = false"
            >
            {{ $t('backoffice.roles.discard') }}
            </b-button>
            <b-button
              variant="primary"
              target="_blank"
              class="ml-md-1 mt-25"
              @click="handleDeleteDefaultRole"
            >
            {{ $t('backoffice.roles.discard-default') }}
            </b-button>
          </div>
        </b-form-group>
      </b-modal>
    </b-card>
  </backoffice-container>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import vSelect from 'vue-select';

export default {
  name: 'Roles',
  components: {
    BackofficeContainer,
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentRole: {
        name: null,
        namespace: '',
      },
      isValid: null,
      isSmall: false,
      page: 1,
      perPage: 15,
      isDefaultModalOpened: false,
      currentItem: '',
      isDefaultRole: '',
      deleteDefaultRole: false,
    };
  },
  computed: {
    isDisabled() {
      return this.currentRole?.name === null || this.currentRole?.name === '';
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    roles() {
      console.log('this.$store.getters.roles?.unpaginated', this.$store.getters.roles?.unpaginated);
      return this.$store.getters.roles?.unpaginated;
    },
    total() {
      return this.$store.getters.roles?.meta?.total;
    },
    fields() {
      return [
        {
          key: 'fullname',
          label: this.$t('backoffice.roles.name'),
        },
        {
          key: 'tagName',
          label: this.$t('backoffice.roles.label'),
        },
        {
          key: 'isGlobal',
          label: this.$t('backoffice.analytics.data-type'),
        },
        {
          key: 'default',
          label: '',
        },
      ];
    },
  },
  watch: {
    page(page, perPage) {
      this.getRoles(page, perPage);
    },
    currentCollective(value) {
      return value;
    },
  },
  async created() {
    await this.getRoles(this.page, this.perPage);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleDeleteDefaultRole() {
      this.deleteDefaultRole = true;
      this.handleDefault();
    },
    async handleDefault() {
      try {
        await this.$store.dispatch('editCommunity', {
          settings: {
            defaultRoleKey: this.currentItem.key,
            ...(this.deleteDefaultRole ? { deleteDefaultRole: true } : ''),
          },
          forceUpdate: true,
        });
        this.notifySuccess(this.$t("backoffice.roles.messages.edit-success"));
        this.isDefaultRole = !this.deleteDefaultRole ? this.currentItem.key : '';
      } catch {
        this.notifyError(this.$t("backoffice.roles.messages.edit-error"));
      }
      this.isDefaultModalOpened = false;
      this.deleteDefaultRole = false;
    },
    handleDefaultRole(item) {
      this.isDefaultModalOpened = true;
      this.currentItem = item;
    },
    async getRoles(page, perPage) {
      console.log('getRoles this.$store.getters.currentCollective.slug', this.$store.getters.currentCollective.slug);
      await this.$store.dispatch('getRoles', {
        communitySlug: this.$store.getters.currentCollective.slug,
        page,
        perPage: 100,
        namespace: 'community'
      });
    },
    async saveRols() {
      this.currentRole.namespace = 'community';
      try {
        if (this.currentRole.name === null || this.currentRole.name === '' || this.isValid === false) {
          this.notifyError(this.$t('backoffice.roles.messages.error'));
        } else {
          await this.$store.dispatch('createItem', {
            item: {
              itemType: 'createRoles',
              customName: 'roles',
              requestConfig: {
                roles: [this.currentRole],
              },
            },
          });
          this.notifySuccess(this.$t('backoffice.roles.messages.success'));
        }
        await this.getRoles(this.page, this.perPage);
      } catch {
        this.notifyError(this.$t('backoffice.roles.messages.error'));
      }
      this.currentRole = {
        name: null,
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    validator() {
      this.isValid = this.roles.every((role) => role.name !== this.currentRole.name);
    },
    getRoleLocation(role) {
      return {
        name: 'role-details',
        params: { role: role.name, id: role.key, communityId: this.$route.params.communityId, page: this.page },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.model-type p:first-letter {
  text-transform: uppercase;
}
</style>
